import React, { useContext } from "react";
import "./style.css";
import workshops from "../../util/workshops.json";

import { useEffect } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";

function Summary() {
  const { data } = useContext(RegistrationContext);
  useEffect(() => {
    window.history.replaceState({}, "", "/");
  }, []);

  const workshop_selection_ids = data.workshop_selection;
  const selected_workshops_list = [];
  workshops.forEach((workshop) => {
    if (
      workshop_selection_ids &&
      workshop_selection_ids.includes(workshop.id)
    ) {
      selected_workshops_list.push(workshop);
    }
  });

  return (
    <div className="flex flex-col h-full grow">
      <div
        style={{ maxWidth: "1152px", width: "100%" }}
        className="flex justify-start flex-col h-full items-center mx-auto lg:py-0 text-left"
      >
        <div
          style={{ maxWidth: "1152px", width: "100%", maxHeight: "100%" }}
          className="bg-white md:mt-0 sm:max-w-md xl:p-0  "
        >
          <div>
            <div className="flex flex-col h-full bg-white rounded-md">
              <div
                style={{ maxWidth: "1152px", width: "100%" }}
                className="flex justify-center flex-col h-full items-center mx-auto lg:py-0 text-left"
              >
                <div className="w-full p-6 space-y-4 md:space-y-6 sm:p-8 summary-page-content">
                  <h1 className="text-ecmlBrown  text-center px-10 py-4 rounded-md text-2xl underline">
                    Your Registration {data.id} is Complete!{" "}
                  </h1>
                  <ul className="text-l">
                    <li>
                      Type: <b>{data.type}</b>
                    </li>
                    <li>
                      Name: <b>{data.full_name}</b>
                    </li>
                    {data.affiliation && (
                      <li>
                        Affiliation:{" "}
                        <b>
                          {data.affiliation.split("\n").map((i) => (
                            <p className="ml-8">{i} </p>
                          ))}
                        </b>
                      </li>
                    )}

                    {data.papers_registered && (
                      <li>
                        Papers registered:{" "}
                        <b>
                          {data.papers_registered.map((i, index) => 
                            <p key={index} className="ml-8">{i}</p>
                          )}
                        </b>
                      </li>
                    )}

                    {data.workshop_selection &&
                      data.workshop_selection.length > 0 && (
                        <li className="mt-4">
                          Your workshop preferences:
                          <ul className="list-disc">
                            {selected_workshops_list.map((workshop, index) => (
                              <li className="ml-8" key={index}>
                                <b>{workshop.name}</b>
                              </li>
                            ))}
                          </ul>
                        </li>
                      )}
                  </ul>
                  <div className="my-5">&nbsp;</div>
                  <div className="bg-blue-50 py-4 pr-4 pl-5 border-l-4 border-stone-800 text-gray-500">
                    We encourage you to book your accommodations as soon as
                    possible. This is a busy period, and prices are likely to
                    increase. Secure your stay early to ensure the best rates
                    and availability.
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col h-full  mt-8">
              <div
                style={{
                  maxWidth: "1152px",
                  width: "100%",
                  minHeight: "150px",
                  backgroundImage: `url('https://d1u5q3vcv714vh.cloudfront.net/eyJidWNrZXQiOiJlY21scGtkZC1zdG9yYWdlIiwia2V5IjoiYmFja2dyb3VuZF83ZDc0ZGUwYTZjLndlYnAifQ==')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "10px",
                }}
                className=" h-full  mx-auto lg:py-0 text-left"
              >
                <div
                  style={{
                    color: "white",
                    fontSize: "50px",
                    fontWeight: "900",
                    paddingLeft: "40px",
                  }}
                >
                  See you soon in Vilnius :)
                </div>
                <div
                  style={{
                    color: "white",
                    fontSize: "20px",
                    fontWeight: "90",
                    paddingLeft: "40px",
                  }}
                >
                  For travel information and exclusive accommodation deals, be
                  sure to visit our{" "}
                  <a
                    style={{ textDecoration: "underline" }}
                    target="_blank"
                    href="https://ecmlpkdd.org/2024/"
                    rel="noreferrer"
                  >
                    website
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
