import { useId, useContext, useEffect, useMemo, useState } from "react";

import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { ChefHat, Handshake, Mails, Printer, Wine } from "lucide-react";
import { RiFileExcel2Line } from "@remixicon/react";
import { base_url } from "../../config";
import axios from "axios";
import { Box, MenuItem, Tooltip } from "@mui/material";
import RegistrationContext from "../../contexts/RegistrationContext";
import SidePanel from "../../components/SidePanel/SidePanel";
import { User } from "lucide-react";
import "./style.css";
import workshops from "../../util/workshops.json";
import BadgesFilter from "../../components/BadgesFilter/BadgesFilter";
import FemaleRoundedIcon from "@mui/icons-material/FemaleRounded";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const RegistrationDetails = ({ data }) => {
  if (!data["registration_id"]) return <div></div>;

  const RegistrationStatus = ({ data }) => {
    if (data["step"] === "Confirmation") {
      return <>{"Registration Complete"}</>;
    } else {
      return <>{"Registration In Progress"}</>;
    }
  };

  const QA = ({ q, a }) => (
    <div>
      <span>{q}</span> <span className="text-blue-500">{a}</span>
    </div>
  );

  return (
    <div className=" container flex flex-col justify-center px-6 py-8 mx-auto md:h-full lg:py-0 text-left">
      <div className="flex text-white gap-4 w-full">
        <div>
          <User size={30} />
        </div>
        <div
          className="sectionTitle"
          style={{ color: "white", marginBottom: "50px" }}
        >
          Registration {data["registration_id"]} -{" "}
          <RegistrationStatus data={data} />
        </div>
      </div>
      <div className="w-full bg-white rounded-lg shadow   ">
        <div className="w-full p-6 space-y-4 md:space-y-6 sm:p-8">
          <form className="space-y-4 md:space-y-6 font-normal">
            <div>
              <div className=" flex justify-between  w-full  border-b border-b-gray-200 pb-2">
                <div style={{ width: "300px" }}>
                  <div className="text-2xl font-bold">
                    {data["ecml_extra_registration"]["full_name"]}{" "}
                  </div>

                  <div className="text-blue-500">
                    <a href={`mailto:${data["user"]["email"]}`}>
                      {" "}
                      {data["user"]["email"]}
                    </a>
                  </div>

                  {data["ecml_extra_registration"]["affiliation"] && (
                    <div>{data["ecml_extra_registration"]["affiliation"]} </div>
                  )}
                  {data["ecml_extra_registration"]["affiliation2"] && (
                    <div>
                      {data["ecml_extra_registration"]["affiliation2"]}{" "}
                    </div>
                  )}
                </div>
                <div style={{ width: "300px" }}>
                  <div className="middle-title">Billing Info</div>
                  {data["ecml_extra_registration"]["full_name"] && (
                    <div>{data["ecml_extra_registration"]["full_name"]} </div>
                  )}

                  {data["ecml_extra_registration"]["recipient_name"] && (
                    <div>
                      {data["ecml_extra_registration"]["recipient_name"]}{" "}
                    </div>
                  )}
                  {data["ecml_extra_registration"]["institution"] && (
                    <div>{data["ecml_extra_registration"]["institution"]} </div>
                  )}
                  {data["ecml_extra_registration"]["address1"] && (
                    <div>{data["ecml_extra_registration"]["address1"]} </div>
                  )}
                  {data["ecml_extra_registration"]["address2"] && (
                    <div>{data["ecml_extra_registration"]["address2"]} </div>
                  )}
                  {data["ecml_extra_registration"]["tax_id"] && (
                    <div>{data["ecml_extra_registration"]["tax_id"]} </div>
                  )}

                  {data["ecml_extra_registration"]["country"] && (
                    <div>{data["ecml_extra_registration"]["country"]} </div>
                  )}
                </div>
              </div>
              <div className=" flex justify-between  w-full mt-8 ">
                <div style={{ width: "100%" }}>
                  <div class="middle-title">Selection</div>
                  <QA
                    q={"Gender:"}
                    a={capitalizeFirstLetter(
                      data["ecml_extra_registration"]["gender"]
                    )}
                  />

                  <QA
                    q={"Dietary Requirements:"}
                    a={data["ecml_extra_registration"]["dietary_requirements"]}
                  />

                  <QA
                    q={"T-shirt (cut-size):"}
                    a={data["ecml_extra_registration"]["t_shirt"]}
                  />

                  <QA
                    q={"Confirms assistance to gala dinner:"}
                    a={
                      data["ecml_extra_registration"]["gala_dinner"]
                        ? "Yes"
                        : "No"
                    }
                  />

                  <QA
                    q={"Purchased a ticket for farewell event:"}
                    a={
                      data["ecml_extra_registration"]["farewell"] ? "Yes" : "No"
                    }
                  />

                  <QA
                    q={"Wants to be contacted regarding the women lunch:"}
                    a={
                      data["ecml_extra_registration"]["women_lunch"]
                        ? "Yes"
                        : "No"
                    }
                  />

                  <QA
                    q={"Needs a visa letter invitation:"}
                    a={
                      data["ecml_extra_registration"]["visa_letter"]
                        ? "Yes"
                        : "No"
                    }
                  />

                  <QA
                    q={"Needs to print a poster:"}
                    a={data["ecml_extra_registration"]["poster"] ? "Yes" : "No"}
                  />

                  {data["ecml_extra_registration"]["accompanying_dinner"] >
                    0 && (
                    <QA
                      q={"People accompanying to the dinner:"}
                      a={data["ecml_extra_registration"]["accompanying_dinner"]}
                    />
                  )}
                  <QA
                    q={"Wants to be recruited by a sponsor:"}
                    a={
                      data["ecml_extra_registration"]["sponsors_recruitment"]
                        ? "Yes"
                        : "No"
                    }
                  />
                </div>
              </div>

              {data["ecml_extra_registration"]["workshop_selection"] && (
                <div className=" flex justify-between  w-full mt-8 ">
                  <div style={{ width: "100%" }}>
                    <div class="middle-title">Workshops</div>
                    {data["ecml_extra_registration"]["workshop_selection"].map(
                      (w, i) => (
                        <div key={i}>
                          <ul className="pl-6 list-disc">
                            <li className="font-bold">
                              {workshops.filter((w1) => w1.id == w)[0].name}{" "}
                            </li>
                          </ul>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}

             
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default function App() {
  const { setError, reload, setReload } = useContext(RegistrationContext);
  const columns = useMemo(
    () => [
      {
        accessorKey: "id", //simple recommended way to define a column
        header: "ID",
        muiTableHeadCellProps: { style: { color: "black" } }, //custom props
        enableHiding: false, //disable a feature for this column
      },
      {
        accessorKey: "name", //simple recommended way to define a column
        header: "Name",
        muiTableHeadCellProps: { style: { color: "black" } }, //custom props
        enableHiding: false, //disable a feature for this column
      },
      {
        accessorKey: "email", //simple recommended way to define a column
        header: "eMail",
        muiTableHeadCellProps: { style: { color: "black" } }, //custom props
        enableHiding: false, //disable a feature for this column
      },

      {
        accessorKey: "type", //simple recommended way to define a column
        header: "Type",
        muiTableHeadCellProps: { style: { color: "black" } }, //custom props
        enableHiding: false, //disable a feature for this column
      },
      {
        accessorKey: "state", //simple recommended way to define a column
        header: "State",
        muiTableHeadCellProps: { style: { color: "black" } }, //custom props
        enableHiding: false, //disable a feature for this column
        // custom conditional format and styling
        // https://www.material-react-table.com/docs/examples/advanced
        Cell: ({ cell }) => {
          return (
            cell.getValue() && (
              <Box
                component="span"
                sx={(theme) => ({
                  backgroundColor:
                    cell.getValue() === "completed"
                      ? theme.palette.success.light
                      : theme.palette.error.light,
                  borderRadius: "0.25rem",
                  color: "#fff",
                  p: "0.3rem",
                })}
              >
                {cell.getValue()}
              </Box>
            )
          );
        },
      },
      {
        accessorKey: "selection",
        header: "Selection",
        muiTableHeadCellProps: { style: { color: "black" } },
        enableHiding: false,
        size: 180,
        Cell: ({ cell }) => (
          <Box component="ul" sx={{ padding: 0, margin: 0, listStyle: "none" }}>
            {cell.getValue() &&
              cell.getValue().map((selectionItem, index) => (
                <li key={index}>
                  {Object.entries(selectionItem).map(
                    ([key, isSelected]) =>
                      isSelected && (
                        <div
                          key={key}
                          style={{ display: "inline-block", margin: "0 5px" }}
                        >
                          {key === "needVisa" && isSelected && (
                            <Tooltip title="Visa letters" placement="top-start">
                              <Mails />
                            </Tooltip>
                          )}
                          {key === "poster" && isSelected && (
                            <Tooltip title="Poster" placement="top-start">
                              <Printer />
                            </Tooltip>
                          )}
                          {key === "galaDinner" && isSelected && (
                            <Tooltip title="Gala dinner" placement="top-start">
                              <ChefHat />
                            </Tooltip>
                          )}
                          {key === "farewellEvent" && isSelected && (
                            <Tooltip
                              title="Farewell event"
                              placement="top-start"
                            >
                              <Wine />
                            </Tooltip>
                          )}
                          {key === "sponsor" && isSelected && (
                            <Tooltip title="Sponsor" placement="top-start">
                              <Handshake />
                            </Tooltip>
                          )}
                          {key === "womanLunch" && isSelected && (
                            <Tooltip title="Woman lunch" placement="top-start">
                              <FemaleRoundedIcon
                                style={{ display: "flex", fontSize: "28" }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      )
                  )}
                </li>
              ))}
          </Box>
        ),
      },
    ],
    []
  );

  const [details, setDetails] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});

  const handleImpersonate = async (row) => {
    try {
      const response = await axios.post(`${base_url}/api/admin/impersonate`, {
        id: row.getValue("id"),
      });
      setReload(reload + 1);
    } catch (error) {
      setError("Impersonation did not succeed");
    }
  };

  const handleView = async (closeMenu, row) => {
    closeMenu();
    try {
      const response = await axios.post(`${base_url}/api/admin/get_details`, {
        id: row.getValue("id"),
      });
      setShowDetails(true);
      setDetails(response.data);
    } catch (error) {
      setError("Could not get registration details");
    }
  };

  const handleFilter = (event, column, filter) => {
    const selectedFiltersByColumn = event.target.checked
      ? { ...filters, [column]: [...(filters[column] || []), filter] }
      : { ...filters, [column]: filters[column].filter((f) => f !== filter) };

    // Remove column if its array is empty
    if (selectedFiltersByColumn[column].length === 0) {
      delete selectedFiltersByColumn[column];
    }

    setFilters(selectedFiltersByColumn);

    const filteredData = originalData.filter((item) =>
      Object.entries(selectedFiltersByColumn).every(([column, filterArray]) =>
        filterArray.includes(item[column])
      )
    );

    setData(filteredData);
  };

  useEffect(() => {
    const loadData = async () => {
      const comm = await axios.get(`${base_url}/api/admin/registrations`);
      setData(comm.data);
      setOriginalData(comm.data);
    };

    loadData();
    /*
    let comm = [
      {
        id: "R6KRTVU",
        name: "Linas Petkevičius",
        email: "linas.mifsa.vu@gmail.com",
        type: "standard",
        state: "in progress",
        selection: [
          {
            needVisa: true,
            poster: true,
            galaDinner: true,
            farewellEvent: true,
            sponsor: true,
            womanLunch: true,
          },
        ],
      },
      {
        id: "RTVTRBD",
        name: "Francisco Cruz",
        email: "titocru@gmail.com",
        type: "standard",
        state: "in progress",
        selection: [
          {
            needVisa: true,
            poster: true,
            galaDinner: true,
            farewellEvent: false,
            sponsor: true,
            womanLunch: true,
          },
        ],
      },
      {
        id: "RAVL483",
        name: "Linas Petkevičius",
        email: "linas.petkevicius@mif.vu.lt",
        type: "standard",
        state: "completed",
        selection: [
          {
            needVisa: false,
            poster: true,
            galaDinner: true,
            farewellEvent: true,
            sponsor: false,
            womanLunch: true,
          },
        ],
      },
    ];
    */
  }, []);

  //pass table options to useMaterialReactTable
  const table = useMaterialReactTable({
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    initialState: { density: "compact" },
    enableRowSelection: false, //enable some features
    enableColumnOrdering: true, //enable a feature for all columns
    enableGlobalFilter: true, //turn off a feature
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActionMenuItems: ({ closeMenu, row }) => [
      <MenuItem key="view" onClick={() => handleView(closeMenu, row)}>
        View
      </MenuItem>,
      <MenuItem key="impersonate" onClick={() => handleImpersonate(row)}>
        Impersonate
      </MenuItem>,
    ],
    muiTableHeadCellProps: {
      // simple styling with the `sx` prop, works just like a style prop in this example
      // https://www.material-react-table.com/docs/guides/customize-components#the-sx-prop
      sx: {
        backgroundColor: "#e3f1ff",
      },
    },
  });

  const downLoadExcel = async () => {
    try {
      const response = await axios.get(`${base_url}/api/admin/export_excel`, {
        responseType: "blob",
      });
      const pdfBlob = new Blob([response.data], { type: "application/xlsx" });

      const url = window.URL.createObjectURL(pdfBlob);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", `ECML-Registrations.xlsx`); // Set the desired filename for the downloaded file

      // Append the <a> element to the body and click it to trigger the download
      document.body.appendChild(tempLink);
      tempLink.click();

      // Clean up the temporary elements and URL
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  return (
    <>
      <div className="flex flex-col v-full h-full ">
        <div className="flex justify-between flex px-8 py-4 absolute w-full top-0 left-0">
          <div className="flex flex-wrap justify-end w-full">
            <BadgesFilter
              key={useId()}
              index={useId()}
              title="Completed"
              bgColor="#1c9d00"
              handleFilter={(e) => handleFilter(e, "state", "completed")}
            />
            <BadgesFilter
              key={useId()}
              index={useId()}
              title="In Progress"
              bgColor="#FF1300"
              handleFilter={(e) => handleFilter(e, "state", "in progress")}
            />
            <BadgesFilter
              key={useId()}
              index={useId()}
              title="Standard"
              bgColor="#df00ff"
              handleFilter={(e) => handleFilter(e, "type", "standard")}
            />
            <BadgesFilter
              key={useId()}
              index={useId()}
              title="Student"
              bgColor="#ff6600"
              handleFilter={(e) => handleFilter(e, "type", "student")}
            />
            <BadgesFilter
              key={useId()}
              index={useId()}
              title="Single"
              bgColor="#0034ff"
              handleFilter={(e) => handleFilter(e, "type", "single")}
            />
          </div>

          <div
            className=" flex flex-end cursor-pointer ml-8"
            onClick={() => downLoadExcel()}
          >
            {" "}
            <RiFileExcel2Line className="text-green-800" /> Export
          </div>
        </div>

        <div>
          <MaterialReactTable table={table} />
        </div>
      </div>
      <SidePanel isOpen={showDetails} openCloseHandler={setShowDetails}>
        <div style={{ width: "1000px" }}>
          <RegistrationDetails data={details} />
        </div>
      </SidePanel>
    </>
  );
}
