// src/contexts/RegistrationProvider.js

import React, { useState } from "react";
import RegistrationContext from "./RegistrationContext";

const RegistrationProvider = ({ children }) => {
  const [screen, setScreen] = useState("login");
  const [maxStep, setMaxStep] = useState("login");
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const [reload, setReload] = useState(0);
  const [data, setData] = useState({});
  const [cartOpen, setCartOpen] = useState(false);
  const [cart, setCart] = useState({});
  const [cartAdded, setCartAdded] = useState(false);
  const [userInfoOpen, setUserInfoOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [phase, setPhase] = useState("");
  const [type, setType] = useState("");
  const [inCommunication, setInCommunication] = useState(false);
  const [paperOpen, setPaperOpen] = useState(false);
  const [paperSelected, setPaperSelected] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isImpersonating, setIsImpersonating] = useState(false);
  const [next, setNext] = useState(null);
  const [prices, setPrices] = useState({});
  const [deadline, setDeadline] = useState({});


  return (
    <RegistrationContext.Provider
      value={{
        screen,
        setScreen,
        authenticated,
        setAuthenticated,
        loading,
        setLoading,
        error,
        setError,
        status,
        setStatus,
        reload,
        setReload,
        maxStep,
        setMaxStep,
        data,
        setData,
        cartOpen,
        setCartOpen,
        cart,
        setCart,
        cartAdded,
        setCartAdded,
        userInfoOpen,
        setUserInfoOpen,
        email,
        setEmail,
        phase,
        setPhase,
        type,
        setType,
        inCommunication,
        setInCommunication,
        paperOpen,
        setPaperOpen,
        paperSelected,
        setPaperSelected,
        isAdmin,
        setIsAdmin,
        isImpersonating,
        setIsImpersonating,
        next,
        setNext,
        prices,
        setPrices,
        deadline,
        setDeadline,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};

export default RegistrationProvider;
